/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import React, { useRef } from 'react'
// import Logo from '../assets/images/logo-tosa.png'
import LogoPT from '../assets/images/pt-tosa.jpeg'

const PrintTest = () => {
  const ref = useRef()

  //   const handlePrint = useReactToPrint({
  //     content: () => ref.current,
  //     documentTitle: 'STRUK'
  //   })

  //   useEffect(() => {
  //     setTimeout(() => {
  //       handlePrint()
  //     }, 1000)
  //   }, [])

  return (
        <div>
            <div ref={ref}>
                <div style={{ fontSize: '10px', color: 'black', fontWeight: '500', border: '1px solid black', margin: '3rem', marginTop: '50px' }} className='font-calibri'>
                    <CRow className='align-items-center'>
                        <CCol md={8} xs={8} className='p-4 py-0'>
                            <img src={LogoPT} alt='logo' className='img-fluid p-1 me-3' width={120} height={120} />
                            <div className='d-flex align-items-center'>
                                <div>
                                    <p className='mb-0'>Jl. Raya Luragung - Mekarwangi Lebakwangi Kuningan Telp (0232) 8910565</p>
                                    <p className='mb-0'>NPWP: 65.067.775.0-438.000</p>
                                    <p className='mb-0'>IZIN PBF: 12042200157340001</p>
                                    <p className='mb-0'>NO SERTIFIKAT CDOB : CDOB4834/S/4-4607/09/2023</p>
                                </div>
                            </div>
                        </CCol>
                        <CCol md={4} xs={4} className='py-0'>
                            <div className='float-end d-flex flex-column' style={{ border: '1px solid black', padding: '10px', borderRight: 0 }}>
                                <p className='mb-0'>KEPADA YTH:</p>
                                <p className='mb-0'>Pelanggan: Apotek Tosa</p>
                                <p className='mb-0'>Alamat: Jl. Raya Luragung, Mekarwangi, Lebakwangi</p>
                            </div>
                        </CCol>
                    </CRow>
                    <CTable style={{ color: '#000000', borderColor: '#000000', borderTop: '1px solid' }} bordered>
                        <CTableHead>
                            <CTableRow style={{ fontWeight: 'bold' }}>
                                <CTableHeaderCell scope="col">SLS</CTableHeaderCell>
                                <CTableHeaderCell scope="col">TANGGAL</CTableHeaderCell>
                                <CTableHeaderCell scope="col">NO. FAKTUR</CTableHeaderCell>
                                <CTableHeaderCell scope="col">JATUH TEMPO</CTableHeaderCell>
                                <CTableHeaderCell scope="col">NO. SP</CTableHeaderCell>
                                <CTableHeaderCell scope="col" colSpan={3}>NPWP</CTableHeaderCell>
                                <CTableHeaderCell scope="col">OPR</CTableHeaderCell>
                                <CTableHeaderCell scope="col">KETERANGAN</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableDataCell className='py-0'>1</CTableDataCell>
                                <CTableDataCell className='py-0'>23-09-2023</CTableDataCell>
                                <CTableDataCell className='py-0'>RS/TPM/123/12312</CTableDataCell>
                                <CTableDataCell className='py-0'>23-09-2023</CTableDataCell>
                                <CTableDataCell className='py-0'>12312</CTableDataCell>
                                <CTableDataCell className='py-0' colSpan={3}>12313123123123123123</CTableDataCell>
                                <CTableDataCell className='py-0'></CTableDataCell>
                                <CTableDataCell className='py-0'></CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                        <CTableHead>
                            <CTableRow style={{ fontWeight: 'bold' }}>
                                <CTableHeaderCell scope="col">No</CTableHeaderCell>
                                <CTableHeaderCell scope="col" colSpan={3}>NAMA BARANG</CTableHeaderCell>
                                <CTableHeaderCell scope="col">ED/BATCH NO</CTableHeaderCell>
                                <CTableHeaderCell scope="col">JUMLAH BARANG</CTableHeaderCell>
                                <CTableHeaderCell scope="col">SATUAN</CTableHeaderCell>
                                <CTableHeaderCell scope="col">HARGA SATUAN</CTableHeaderCell>
                                <CTableHeaderCell scope="col">DISC</CTableHeaderCell>
                                <CTableHeaderCell scope="col">JUMLAH</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableDataCell className='py-0'>1</CTableDataCell>
                                <CTableDataCell className='py-0' colSpan={3}>Paracetamol</CTableDataCell>
                                <CTableDataCell className='py-0'>07/2025 - PG0011</CTableDataCell>
                                <CTableDataCell className='py-0'>7</CTableDataCell>
                                <CTableDataCell className='py-0'>BOX</CTableDataCell>
                                <CTableDataCell className='py-0'>507.000</CTableDataCell>
                                <CTableDataCell className='py-0'>5%</CTableDataCell>
                                <CTableDataCell className='py-0'>3.371.550</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='py-0' colSpan={8}>Terbilang: Enam Juta Sembilan Ratus Lima Ribu Sembilan Ratus Dua Puluh Satu Rupiah</CTableDataCell>
                                <CTableDataCell className='py-0'>SUB TOTAL</CTableDataCell>
                                <CTableDataCell className='py-0'>3.371.550</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='py-0' colSpan={8}></CTableDataCell>
                                <CTableDataCell className='py-0'>DISC TUNAI</CTableDataCell>
                                <CTableDataCell className='py-0'>3.371.550</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='py-0' colSpan={8}></CTableDataCell>
                                <CTableDataCell className='py-0'>PPN</CTableDataCell>
                                <CTableDataCell className='py-0'>3.371.550</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='py-0' colSpan={8}></CTableDataCell>
                                <CTableDataCell className='py-0'>TOTAL</CTableDataCell>
                                <CTableDataCell className='py-0'>3.371.550</CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                    </CTable>
                    <CRow>
                        <CCol md={6}>
                            <div className='d-flex p-2'>
                                <div className='me-3'>
                                    <p className='text-center mb-5'>Diterima Oleh,</p>
                                    <p className='text-center mb-0'>(..............................)</p>
                                </div>
                                <div>
                                    <p className='mb-0'>PERHATIAN:</p>
                                    <ol className='ps-3 mb-0'>
                                        <li>Faktur Asli merupakan bukti pembayaran yang sah</li>
                                        <li>Pembayaran dengan CEK/GIRO dianggap sah setelah selesai clearing</li>
                                        <li>SMS Keluhan: 081381633702 Format: TPM#Keluhan anda</li>
                                    </ol>
                                    <p className='mb-0'>Barang yang diterima outlet menjadi tanggung jawab outlet</p>
                                </div>
                            </div>
                        </CCol>
                        <CCol md={6}>
                            <div>
                                <p className='text-center mb-0'>PT. TOSA PHARMA MEDIKA KUNINGAN</p>
                                <p className='text-center mb-5'>Penanggung Jawab</p>
                                <p className='text-center mb-0'>(Apt. Risma Ade Purwanti, S.Farm)</p>
                            </div>
                        </CCol>
                    </CRow>
                </div>
            </div>
        </div>
  )
}

export default PrintTest
